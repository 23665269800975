import html2canvas from 'html2canvas';
import { defaultCanvas } from '@/components/meeting/ViewerDrawer/helpers';
export default {
    data: function () {
        return {
            canvas: null,
            viewerElement: null,
            canvasElement: null,
            canvasCords: '',
            showDrawerMenu: false,
            downloadImgUrl: null,
            screenshotContainer: null
        };
    },
    methods: {
        setDrawMode: function () {
            var _this = this;
            this.showDrawerMenu = true;
            var screenshotContainer = document.querySelector('.meeting-container');
            this.viewerElement = document.querySelector('.canvas-wrap canvas');
            var height = window.innerHeight;
            html2canvas(this.viewerElement, { height: height }).then(function (canvas) {
                _this.canvasElement = canvas;
                _this.canvasElement.classList.add('canvas-drawer-active');
                screenshotContainer.appendChild(canvas);
                _this.loadCanvas();
            });
        },
        loadCanvas: function () {
            this.btnText = 'save';
            this.downloadImgUrl = null;
            this.canvas = this.canvasElement.getContext('2d');
            var _a = this.canvasElement.getBoundingClientRect(), left = _a.left, top = _a.top;
            var events = [
                { name: 'mousedown', callback: this.drawOn },
                { name: 'mousemove', callback: this.draw },
                { name: 'mouseup', callback: this.drawOff },
                { name: 'click', callback: this.setTextPos },
                { name: 'click', callback: this.setStartPoint }
            ];
            this.canvasCords = {
                x: Math.round(left),
                y: Math.round(top)
            };
            for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                var e = events_1[_i];
                this.canvasElement.addEventListener(e.name, e.callback);
            }
            this.setCanvasOptions(defaultCanvas);
        },
        setCanvasOptions: function (opts) {
            console.log('setCanvasOptions -> opts', opts);
            for (var key in opts) {
                var value = opts[key];
                this.canvas[key] = value;
            }
        },
        addEvents: function (events) {
            this.$log('success', 'add events', events);
            for (var key in events) {
                this.canvasElement.addEventListener(key, events[key]);
            }
        },
        removeEvents: function (events) {
            this.$log('warning', 'remove events', events);
            for (var key in events) {
                this.canvasElement.removeEventListener(key, events[key]);
            }
        },
        returnToViewer: function () {
            this.setMode('draw', 'none');
            this.canvasElement.remove();
            this.showDrawerMenu = false;
            this.canvas = null;
            this.btnText = 'paint';
        }
    }
};
