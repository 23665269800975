export default {
    data: function () {
        return {
            lineStatus: null,
            lineWidth: 1,
            defaultImageData: null,
            newImageData: null
        };
    },
    methods: {
        setStartPoint: function (_a) {
            var clientX = _a.clientX, clientY = _a.clientY;
            if (this.mode !== 'line' || this.lineStatus)
                return;
            this.setCursorPosition(clientX, clientY);
            var _b = this, x = _b.x, y = _b.y;
            this.drawStartingPoint({ x0: x + 1, y0: y + 1, x1: x, y1: y - 1 });
            this.setFromPath({ x: x, y: y });
        },
        drawStartingPoint: function (opts) {
            this.canvas.beginPath();
            this.canvas.moveTo(opts.x0, opts.y0);
            this.canvas.lineTo(opts.x1, opts.y1);
            this.canvas.stroke();
        },
        finishPoint: function () {
            this.lineStatus = null;
            this.toggleEvents();
        },
        drawPath: function () {
            this.canvas.putImageData(this.defaultImageData, 0, 0);
            var _a = this.lineStatus, fromX = _a.fromX, toY = _a.toY;
            var _b = this.canvasElement, width = _b.width, height = _b.height;
            this.canvas.beginPath();
            this.canvas.moveTo(fromX, toY);
            this.canvas.lineTo(this.x, this.y);
            this.canvas.closePath();
            this.canvas.stroke();
            this.canvas.getImageData(0, 0, width, height);
            this.newImageData = this.canvas.getImageData(0, 0, width, height);
        },
        setFromPath: function (from) {
            this.lineStatus = {
                fromX: from.x,
                toY: from.y
            };
            var _a = this.canvasElement, width = _a.width, height = _a.height;
            this.defaultImageData = this.canvas.getImageData(0, 0, width, height);
            this.toggleEvents();
        },
        toggleEvents: function () {
            if (!this.lineStatus) {
                this.removeEvents({ mousemove: this.drawPath, click: this.finishPoint });
                this.addEvents({ click: this.setStartPoint });
            }
            else {
                this.addEvents({ mousemove: this.drawPath, click: this.finishPoint });
                this.removeEvents({ click: this.setStartPoint });
            }
        }
    }
};
