var helpers = {
    setCursor: function (canvas, cursor) { return (canvas.style.cursor = cursor); },
    b64ToUint8Array: function (b64Image) {
        console.log('b64Image', b64Image);
        var img = atob(b64Image.split(',')[1]);
        var img_buffer = [];
        var i = 0;
        while (i < img.length) {
            img_buffer.push(img.charCodeAt(i));
            i++;
        }
        return new Uint8Array(img_buffer);
    },
    dateNowISO: function () {
        var dateNow = new Date().toISOString().split('T');
        var date = dateNow[0];
        var time = dateNow[1].slice(0, 8).split(':').join('·');
        return date + " " + time;
    }
};
var defaultCanvas = {
    font: '30px Arial',
    fillStyle: '#000000',
    strokeStyle: '#000000',
    lineCap: 'round'
};
export default helpers;
export { defaultCanvas };
