import { __awaiter, __generator } from "tslib";
import { ForgeCalls } from './api/helpers';
import axios from 'axios';
export default {
    methods: {
        createStorage: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, $functions, imageName, _b, parentFolder, projectId, body, data, error_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            _a = this, $functions = _a.$functions, imageName = _a.imageName;
                            _b = this.meeting, parentFolder = _b.parentFolder, projectId = _b.projectId;
                            body = ForgeCalls.getBodyStorage(imageName, parentFolder, projectId);
                            return [4, $functions.httpsCallable('createStorage')(body)];
                        case 1:
                            data = (_c.sent()).data;
                            console.log('Response createStorage ->', data);
                            return [2, data];
                        case 2:
                            error_1 = _c.sent();
                            return [2, error_1];
                        case 3: return [2];
                    }
                });
            });
        },
        uploadFile: function (imgId) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, userId, imageUrn, getFormData, getUploadArgs, u8Image, formData, _b, url, config, data, error_2;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.imageUrn = imgId.split('/')[1];
                            _a = this, userId = _a.userId, imageUrn = _a.imageUrn;
                            getFormData = ForgeCalls.getFormData, getUploadArgs = ForgeCalls.getUploadArgs;
                            u8Image = this.b64ToUint8Array(this.downloadImgUrl);
                            formData = getFormData(imageUrn, u8Image, userId);
                            _b = getUploadArgs(formData._boundary), url = _b.url, config = _b.config;
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 3, , 4]);
                            return [4, axios.post(url, formData, config)];
                        case 2:
                            data = (_c.sent()).data;
                            console.log('Response uploadFile ->', data);
                            return [2, data];
                        case 3:
                            error_2 = _c.sent();
                            this.requestError(error_2);
                            return [2, null];
                        case 4: return [2];
                    }
                });
            });
        },
        createVersion: function (_a) {
            var objectId = _a.objectId;
            return __awaiter(this, void 0, void 0, function () {
                var _b, parentFolder, projectId, getVersionBody, _c, imageName, $functions, versionBody, data, error_3;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            _b = this.meeting, parentFolder = _b.parentFolder, projectId = _b.projectId;
                            getVersionBody = ForgeCalls.getVersionBody;
                            _c = this, imageName = _c.imageName, $functions = _c.$functions;
                            versionBody = getVersionBody(imageName, parentFolder, objectId);
                            _d.label = 1;
                        case 1:
                            _d.trys.push([1, 3, , 4]);
                            return [4, $functions.httpsCallable('createVersion')({
                                    versionBody: versionBody,
                                    projectId: projectId
                                })];
                        case 2:
                            data = (_d.sent()).data;
                            console.log('Response createVersion -> ', data);
                            return [2, data];
                        case 3:
                            error_3 = _d.sent();
                            this.requestError(error_3);
                            return [2, null];
                        case 4: return [2];
                    }
                });
            });
        }
    }
};
