export default {
    data: function () {
        return {
            drawing: false
        };
    },
    methods: {
        drawOn: function () {
            if (this.mode !== 'draw')
                return;
            this.drawing = true;
            this.canvas.beginPath();
        },
        draw: function (_a) {
            var clientX = _a.clientX, clientY = _a.clientY;
            this.setCursorPosition(clientX, clientY);
            if (!this.drawing)
                return;
            this.drawing && this.stroke();
        },
        stroke: function () {
            console.log('stroke -> stroke');
            this.canvas.lineTo(this.x, this.y);
            this.canvas.stroke();
        },
        drawOff: function () {
            this.drawing = false;
        }
    }
};
