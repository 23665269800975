function promptDialogOpts(message) {
    if (message === void 0) {
        message = '';
    }
    return {
        message: "Please insert file name \uD83C\uDFF0: <br> <br> <b style=\"color:red\">" + message + "</b>",
        inputAttrs: {
            type: 'text',
            placeholder: 'File name...',
            value: ''
        },
        confirmText: 'Send',
        trapFocus: true,
        closeOnConfirm: false
    };
}
var uploadingToastOpts = {
    message: "Uploading image to Bim360...",
    duration: 3000
};
function errorToastOpts(message) {
    return {
        type: 'is-danger',
        message: message,
        duration: 5000
    };
}
function successToastOpts() {
    return {
        type: 'is-success',
        message: 'Succesfully uploaded file!',
        duration: 3000
    };
}
export { promptDialogOpts, errorToastOpts, successToastOpts, uploadingToastOpts };
