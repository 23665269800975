export default {
    data: function () {
        return {
            writingModal: false,
            text: '',
            textAreaStyles: {}
        };
    },
    methods: {
        setTextPos: function (_a) {
            var clientX = _a.clientX, clientY = _a.clientY;
            console.log('setTextPos -> setTextPos');
            if (this.mode !== 'write')
                return;
            this.writingModal = true;
            this.setCursorPosition(clientX, clientY);
            this.textAreaStyles = {
                left: clientX + 'px',
                top: clientY + 'px',
                color: this.activeColor,
                visibility: 'visible'
            };
        },
        setText: function () {
            var _a = this, text = _a.text, x = _a.x, y = _a.y;
            this.canvas.fillText(text, x, y);
            this.writingModal = false;
            this.text = '';
        }
    }
};
