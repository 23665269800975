export default {
    watch: {
        activeColor: function (value) {
            this.$refs.pen.style.color = value;
            this.setPenStyles({ backgroundColor: value });
        }
    },
    methods: {
        setPenSize: function (lineWidth) {
            var size = lineWidth + 'px';
            this.setPenStyles({ width: size, height: size });
        },
        setPenStyles: function (cssProp) {
            console.log('setPenStyles -> cssProp', cssProp);
            for (var key in cssProp) {
                this.$refs.pen.style[key] = cssProp[key];
            }
        }
    }
};
