import { __awaiter, __generator } from "tslib";
import { errorToastOpts, successToastOpts, uploadingToastOpts } from './api/buefyNotification';
import { ForgeCalls } from './api/helpers';
export default {
    data: function () {
        return {
            imageName: '',
            imageUrn: '',
            imageId: ''
        };
    },
    methods: {
        uploadApi: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, is2xxResponse, requestError, storage, upload, version, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this, is2xxResponse = _a.is2xxResponse, requestError = _a.requestError;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 5, , 6]);
                            return [4, this.createStorage()];
                        case 2:
                            storage = _b.sent();
                            if (!is2xxResponse(storage.statusCode))
                                return [2, requestError(storage)];
                            console.log('**storage**', storage);
                            return [4, this.uploadFile(storage.body.data.id)];
                        case 3:
                            upload = _b.sent();
                            if (!upload)
                                return [2];
                            return [4, this.createVersion(upload.body)];
                        case 4:
                            version = _b.sent();
                            if (!is2xxResponse(version.statusCode))
                                return [2, requestError(version)];
                            return [2, version];
                        case 5:
                            error_1 = _b.sent();
                            console.log('uploadApi -> error', error_1);
                            return [2, requestError(error_1)];
                        case 6: return [2];
                    }
                });
            });
        },
        is2xxResponse: function (statusCode) {
            return statusCode.toString().charAt(0) === '2';
        },
        requestError: function (error) {
            console.log('%c Request Error', 'color: red; font-weight:bold;', {
                error: error
            });
            var statusCode = error.statusCode, statusBody = error.statusBody;
            if (statusCode === 409)
                return statusBody.errors[0].detail + '. Please enter a different name.';
            if (statusBody.errors[0].detail)
                return statusBody.errors[0].detail;
            return error;
        },
        saveImgToCloud: function () {
            return __awaiter(this, void 0, void 0, function () {
                var user, date, response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            !this.downloadImgUrl && (this.downloadImgUrl = this.canvasElement.toDataURL('png'));
                            user = this.$auth.currentUser.displayName;
                            date = this.dateNowISO();
                            this.imageName = date + " " + user + ".png";
                            this.$buefy.toast.open(uploadingToastOpts);
                            return [4, this.uploadApi()];
                        case 1:
                            response = _a.sent();
                            if (response.statusCode === 201) {
                                this.$buefy.toast.open(successToastOpts());
                                this.returnToViewer();
                            }
                            else {
                                this.$buefy.toast.open(errorToastOpts(response));
                            }
                            return [2];
                    }
                });
            });
        },
        storeCreate: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, $functions, imageName, _b, parentFolder, projectId, body, data;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _a = this, $functions = _a.$functions, imageName = _a.imageName;
                            _b = this.meeting, parentFolder = _b.parentFolder, projectId = _b.projectId;
                            body = ForgeCalls.getBodyStorage(imageName, parentFolder, projectId);
                            return [4, $functions.httpsCallable('createStorage')(body)];
                        case 1:
                            data = (_c.sent()).data;
                            console.log('Response createStorage ->', data);
                            return [2, data];
                    }
                });
            });
        }
    }
};
