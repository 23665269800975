var ForgeCalls = {
    getBodyStorage: function (imgName, folderId, projectId) {
        return {
            body: {
                jsonapi: {
                    version: '1.0'
                },
                data: {
                    type: 'objects',
                    attributes: {
                        name: imgName
                    },
                    relationships: {
                        target: {
                            data: {
                                type: 'folders',
                                id: folderId
                            }
                        }
                    }
                }
            },
            projectId: projectId
        };
    },
    getFormData: function (imageName, u8Image, userId) {
        var formData = new FormData();
        var imgData = new Blob([u8Image], { type: 'image/png' });
        formData.append(imageName, imgData, userId);
        return formData;
    },
    getUploadArgs: function (boundary) {
        var url = process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + 'api/uploadfile/';
        var config = {
            headers: {
                'content-type': "multipart/form-data; boundary=" + boundary
            }
        };
        return { url: url, config: config };
    },
    getVersionBody: function (fileName, folderId, objectId) {
        return {
            'jsonapi': {
                'version': '1.0'
            },
            'data': {
                'type': 'items',
                'attributes': {
                    'displayName': fileName,
                    'extension': {
                        'type': 'items:autodesk.bim360:File',
                        'version': '1.0'
                    }
                },
                'relationships': {
                    'tip': {
                        'data': {
                            'type': 'versions',
                            'id': '1'
                        }
                    },
                    'parent': {
                        'data': {
                            'type': 'folders',
                            'id': folderId
                        }
                    }
                }
            },
            'included': [
                {
                    'type': 'versions',
                    'id': '1',
                    'attributes': {
                        'name': fileName,
                        'extension': {
                            'type': 'versions:autodesk.bim360:File',
                            'version': '1.0'
                        }
                    },
                    'relationships': {
                        'storage': {
                            'data': {
                                'type': 'objects',
                                'id': objectId
                            }
                        }
                    }
                }
            ]
        };
    }
};
export { ForgeCalls };
